import type { Manifest } from "@twoday/env-public-config-and-messages";
import { pickManifest } from "@twoday/env-public-config-and-messages";
import envManifest_ from "./.env-manifest.json";

const override = ENV.env;
//const override = "asiointi.qa.pirha.fi";

const envManifest = override
  ? pickManifest(envManifest_ as Manifest, override)
  : (envManifest_ as Manifest);

export default envManifest;
