import "@twoday/public.config";

import definition from "@/api/schema.json";
import isAbsoluteURL from "@/utils/isAbsoluteURL";
import type { FaviconManifestEntry } from "@twoday/env-public-config-and-messages";
import {
  getFilePath,
  getManifestEntry,
} from "@twoday/env-public-config-and-messages";
import loadRuntimeConfig from "@twoday/public.config/lib/loadRuntimeConfig.js";
import type Keycloak from "keycloak-js";
import { type KeycloakLoginOptions } from "keycloak-js";
import React from "react";
import * as ReactDOM from "react-dom/client";
import envManifest from "./envManifest";
import "./index.css";
import {
  BookingHealthcareServiceDisclaimer,
  BookingHealthcareServiceLocation,
  BookingHealthcareServiceModalDisclaimer,
  BookingHealthcareServiceType,
  FeatureFlag,
} from "./types/vite-env";

declare global {
  var ENV: {
    BACKEND: {
      baseURL: string;
    };
    LOCALES: string[];
    KEYCLOAK_LOGIN_OPTIONS?: KeycloakLoginOptions;
    KEYCLOAK_MOCK_USER?: string;
    KEYCLOAK: Parameters<typeof Keycloak>[0];
    GIT_AUTHOR_DATE: string;
    GIT_VERSION: string;
    MOCK?: string;
    BASENAME?: string;
    PROD: boolean;
    MODE: "development" | "production";
    FEATURE_FLAGS?: FeatureFlag[];

    // Healthcare booking services.
    HEALTHCARE_BOOKING_SERVICES?: BookingHealthcareServiceType[];
    HEALTHCARE_BOOKING_SERVICE_LOCATIONS?: BookingHealthcareServiceLocation[];
    HEALTHCARE_BOOKING_SERVICE_DISCLAIMERS?: BookingHealthcareServiceDisclaimer[];
    HEALTHCARE_BOOKING_SERVICE_MODAL_DISCLAIMERS?: BookingHealthcareServiceModalDisclaimer[];

    // Matomo tracking.
    MATOMO_URL?: string;
    MATOMO_IDSITE?: string;
    env?: string;

    // Maintinance.
    MAINTENANCE_BANNER_POLLING_INTERVAL_MS?: number;
    MAINTENANCE_BANNER_ENABLED?: boolean;
    MAINTENANCE_BANNER_CONTENT?: string;

    // Terms of use.
    TERMS_OF_USE_LAST_UPDATED?: string; // YYYY-MM-DD

    // Application version number.
    APPLICATION_VERSION_NUMBER?: string; // e.g. 1.0.0
  };
}

declare global {
  interface Window {
    _paq: any;
  }
}

const envPath = getFilePath(envManifest, [({ type }) => type === "env"]);
const favicon = getManifestEntry(envManifest, [
  ({ type }) => type === "favicon",
]);

if (favicon) {
  (favicon.entry as FaviconManifestEntry).html
    .map((html) =>
      html.replace('href="', `href="${ENV.BASENAME ?? ""}/${favicon.path}`)
    )
    .map((html) => {
      const div = document.createElement("div");
      div.innerHTML = html.trim();
      return div.firstChild!;
    })
    .forEach((element) =>
      document.getElementsByTagName("head")[0].appendChild(element)
    );
}

async function main() {
  if (envPath) {
    await loadRuntimeConfig(`${ENV.BASENAME ?? ""}/${envPath}`);
  }

  /*if (ENV.MOCK === "msw") {
    console.log("Start mocks")
    const { worker } = await import("./mocks/browser.js");

    await worker.start({
      onUnhandledRequest: "bypass",
    });
  }*/

  definition.servers![0]!.url = isAbsoluteURL(ENV.BACKEND.baseURL)
    ? ENV.BACKEND.baseURL
    : window.location.origin + ENV.BACKEND.baseURL;

  if (ENV.MATOMO_URL && ENV.MATOMO_IDSITE) {
    // Setup Matomo Tracking
    const _paq = (window._paq = window._paq || []);
    _paq.push(["enableLinkTracking"]);

    (function () {
      const u = ENV.MATOMO_URL;
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", ENV.MATOMO_IDSITE]);
      const d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = u + "matomo.js";
      s.parentNode!.insertBefore(g, s);
    })();
  }

  const App = (await import(/* webpackMode: "eager" */ "./App")).default;

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

main();
